// extracted by mini-css-extract-plugin
export var close = "installationView-module--close--afch0";
export var curThumNail = "installationView-module--curThumNail--RBOze";
export var decription = "installationView-module--decription--NHL-A";
export var desc = "installationView-module--desc--FJzAu";
export var eachTitle = "installationView-module--eachTitle--cs0bg";
export var imgAppear = "installationView-module--imgAppear--O0Ast";
export var imgHide = "installationView-module--imgHide--j4LL+";
export var isShowCSS = "installationView-module--isShowCSS--29N1M";
export var modalArea = "installationView-module--modalArea--O9NsU";
export var modalBg = "installationView-module--modalBg--kkNJu";
export var modalContent = "installationView-module--modalContent--Y6LfZ";
export var modalContentCSS = "installationView-module--modalContentCSS---4hnn";
export var modalWrapper = "installationView-module--modalWrapper--7Oemo";
export var modalWrapperScale = "installationView-module--modalWrapperScale--H48Ns";
export var modalWrapperWrapper = "installationView-module--modalWrapperWrapper--WR47-";
export var thumNail = "installationView-module--thumNail--an1F9";
export var thumNailUme = "installationView-module--thumNailUme--l+2Bg";
export var thumNails = "installationView-module--thumNails--8Ug4O";
export var thumbImg = "installationView-module--thumbImg--kBwLA";